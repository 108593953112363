import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home.vue'),
  },

  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about/about.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/about/contact.vue'),
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import(/* webpackChunkName: "business" */ '../views/business/payment.vue'),
  },
  {
    path: '/tradeFinance',
    name: 'tradeFinance',
    component: () => import(/* webpackChunkName: "business" */ '../views/business/tradeFinance.vue'),
  },
  {
    path: '/risk',
    name: 'risk',
    component: () => import(/* webpackChunkName: "business" */ '../views/business/risk.vue'),
  },

  {
    path: '/education',
    name: 'education',
    component: () => import(/* webpackChunkName: "education" */ '../views/education.vue'),
  },
  {
    path: '/partner',
    name: 'partner',
    component: () => import(/* webpackChunkName: "partner" */ '../views/partner.vue'),
  },
  {
    path: '/privateFx',
    name: 'privateFx',
    component: () => import(/* webpackChunkName: "privateFx" */ '../views/privateFx.vue'),
  },

  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
