import Vue from 'vue';
import Vuex from 'vuex';
import { getLanguage } from '@/utils';
import { getToken, setToken } from '@/utils/cookies';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    language: getLanguage(),
    json: {},
  },
  getters: {
    language: (state) => state.language,
    json: (state) => state.json,
  },
  mutations: {
    SET_LANGUAGE: (state, language) => {
      state.language = language;
      setToken('language', language);
    },
    SET_JSON: (state, json) => {
      state.json = json;
    },
  },
  actions: {
    setLanguage({ commit }, language) {
      commit('SET_LANGUAGE', language);
    },
    setJson({ commit }) {
      Vue.axios.get('./data.json').then((json) => {
        commit('SET_JSON', json);
      });
    },
  },
  modules: {},
});
