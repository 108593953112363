/*
 * @Description: 工具类
 * @Autor: 池樱千幻
 * @Change: 池樱千幻
 * @Date: 2023-02-01 15:51:02
 * @LastEditTime: 2023-02-04 16:29:06
 */

import { getToken } from './cookies';

/**
 * @description: 获取当前语言
 * @return {*}
 * @author: 池樱千幻
 */
export const getLanguage = () => {
  return getToken('language') || 'en';
  
};
