import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '../public/locales/language.json';
import { getLanguage } from '@/utils';

Vue.use(VueI18n);
// 获取语言
const lang = getLanguage();

const i18n = new VueI18n({
  locale: lang,
  messages: messages,
  fallbackLocale: 'en', // 缺省语言 map表没有对应语言 使用该默认语言
  silentTranslationWarn: true,
  missing: (locale, key) => {
    console.log('语言map表缺失该语言', locale, key);
  },
});

Vue.axios.get('./locales/language.json').then((json) => {
  Object.keys(json).forEach((key) => {
    i18n.setLocaleMessage(key, json[key]);
  });
});

export default i18n;
