<template>
  <div id="app" :class="[isFixed ? 'fixedPaddingTop' : '', languageClass]">
    <Header></Header>
    <router-view />
    <Footer :style="{ paddingBottom: $route.path === '/' ? '100px' : '0' }"></Footer>
  </div>
</template>

<script>
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';

import { scrollMixins } from '@/utils/mixins';

export default {
  mixins: [scrollMixins],
  name: 'app',
  data() {
    return {
      isFixed: false,
      isDemo: false,
    };
  },
  watch: {
    $route: {
      handler: function (to, from) {},
      immediate: true,
    },
  },
  computed: {
    languageClass() {
      let className = '';
      switch (this.$i18n.locale) {
        case 'zh':
          className = 'China';
          break;
        case 'en':
          className = 'English';
          break;
        case 'vi':
          className = 'Vietnam';
          break;

        default:
          break;
      }
      return className;
    },
  },
  components: {
    Header,
    Footer,
  },
  created() {
    this.$store.dispatch('setJson');
    this.handleScroll();

    window.onresize = this.onresize;
    this.onresize();
  },
  methods: {
    onresize() {
      let scale = document.body.clientWidth / 1440;
      if (scale < 1) {
        console.log(scale);
        document.documentElement.style.setProperty('--mini-scale', scale);
      }
    },
    handleScroll() {
      // let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      // if (scrollTop > 0) {
      //   this.isFixed = true;
      // } else {
      //   this.isFixed = false;
      // }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/iconfont/iconfont.css';
.fixedPaddingTop {
  padding-top: 104px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
.elTable-header {
  background-color: #0d0b5d !important;
  height: 57px;
  line-height: 57px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  @extend .fontRobotoBold;
}

.table-row-class {
  font-size: 12px;
  color: #000000;
  height: 57px;
  line-height: 57px;
  @extend .fontRobotoBold;
}
.table-row-class-even {
  background-color: rgba($color: #0d0b5d, $alpha: 0.1) !important;
}

.subTitle_red {
  font-size: 1.5vw;
}

.tradeFinance {
  b {
    font-weight: 900;
    font-size: 0.18rem;
    margin-bottom: 0.05rem;
  }
}
.privateFx {
  b {
    font-size: 0.16rem;
    margin-bottom: 0.1rem;
  }
}
</style>
